<template>
  <div>
    <form
      id="registerForm"
      @submit.prevent="validateBeforeSubmit"
    >

       <div :class="{'form-group' : true }">
        <p class="input-label">Name <span class="text-danger"> * </span></p>
        <base-input
          :invalid="$v.registerData.name.$error"
          v-model="registerData.name"
          focus
          type="text"
          name="name"
          @input="$v.registerData.name.$touch()"
        />
        <div v-if="$v.registerData.email.$error">
          <span v-if="!$v.registerData.email.required" class="text-danger">
            {{ $tc('validation.required') }}
          </span>
        </div>
      </div>
    
      <div :class="{'form-group' : true }">
        <p class="input-label">{{ $t('register.email') }} <span class="text-danger"> * </span></p>
        <base-input
          :invalid="$v.registerData.email.$error"
          v-model="registerData.email"
          focus
          type="email"
          name="email"
         
          @input="$v.registerData.email.$touch()"
        />
        <div v-if="$v.registerData.email.$error">
          <span v-if="!$v.registerData.email.required" class="text-danger">
            {{ $tc('validation.required') }}
          </span>
          <span v-if="!$v.registerData.email.email" class="text-danger">
            {{ $tc('validation.email_incorrect') }}
          </span>
        </div>
      </div>

      <div :class="{'form-group' : true }">
        <p class="input-label">Company name <span class="text-danger"> * </span></p>
        <base-input
          :invalid="$v.registerData.company_name.$error"
          v-model="registerData.company_name"
          focus
          type="text"
          name="company_name"
          @input="$v.registerData.company_name.$touch()"
        />
        <div v-if="$v.registerData.email.$error">
          <span v-if="!$v.registerData.email.required" class="text-danger">
            {{ $tc('validation.required') }}
          </span>
        </div>
      </div>


      <div class="form-group">
        <p class="input-label">{{ $t('register.password') }} <span class="text-danger"> * </span></p>
        <base-input
          v-model="registerData.password"
          :invalid="$v.registerData.password.$error"
          type="password"
          name="password"
          show-password
          @input="$v.registerData.password.$touch()"
        />
        <div v-if="$v.registerData.password.$error">
          <span v-if="!$v.registerData.password.required" class="text-danger">{{ $tc('validation.required') }}</span>
          <span v-if="!$v.registerData.password.minLength" class="text-danger"> {{ $tc('validation.password_min_length', $v.registerData.password.$params.minLength.min, {count: $v.registerData.password.$params.minLength.min}) }} </span>
        </div>
      </div>

      <div :class="{ 'form-group': true }">
  <p class="input-label">Country <span class="text-danger">*</span></p>
  <select v-model="registerData.country_id" name="country_id">
    <option :value="null" disabled>Select a country</option>
    <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
  </select>
</div>
  
      <!-- <base-button type="submit" color="theme">{{ $t('register.register') }}</base-button> -->
      <base-button v-if="!isSent" :loading="isLoading" :disabled="isLoading" type="submit" color="theme">
    
      {{ $t('register.register') }}
    </base-button>
    <base-button v-else :loading="isLoading" :disabled="isLoading" color="theme" type="submit">
     
      {{ $t('register.register') }}
    </base-button>
   
    </form>
  
    <div class="other-actions row">
        <div class="col-sm-12 text-sm-left mb-4">
          <p>Already a user please 
      <router-link to="login" class="login-link">
       Login
      </router-link>
      here
      </p>
        </div>
      </div>
  
    </div>
  </template>
  
  <script type="text/babel">
  import { mapActions } from 'vuex'
  
  import IconFacebook from '../../components/icon/facebook'
  import IconTwitter from '../../components/icon/twitter'
  import IconGoogle from '../../components/icon/google'
  import { validationMixin } from 'vuelidate'
  const { required, email, minLength } = require('vuelidate/lib/validators')
  
  export default {
  
    components: {
      IconFacebook,
      IconTwitter,
      IconGoogle
    },
    mixins: [validationMixin],
    data () {
      return {
        registerData: {
          name:'',
          email: '',
          password: '',
          company_name:'',
          country_id: null,
        },
        isSent: false,
        isLoading: false,
        submitted: false,
       
        countries: [],
      //   isQueryParameterSet:false
      }
    },
    validations: {
      registerData: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8)
        },
        name: {
          required
        },
        company_name: {
          required
        }
      }
    },
    mounted() {
      this.fetchCountries()
      
    },
    methods: {
      ...mapActions('auth', [
        'addUsers'
      ]),

      async fetchCountries() {
        try {
          const response = await axios.get('/api/countries'); 
          this.countries = response.data.countries; // Assuming the response contains an array of countries
        } catch (error) {
          console.error('Error fetching countries:', error);
        }
      },

      async validateBeforeSubmit () {

        this.$v.registerData.$touch()
        if (this.$v.$invalid) {
          return true
        }
          // console.log(this.registerData);

          this.isLoading = true;
        let response = await this.addUsers(this.registerData);
       
              // console.log(response);
              if (response.data.success) {
                this.isSent = true;
                this.isLoading = false;
                  // console.log(response.data.success);
                  // alert('Registered Successfully');
                  window.toastr['success']('Registered Successfully, please verify your email address, link send to your mail address!');
                  this.$router.push('/login')

              }
              if (response.data.error) {
                  // console.log(response.data.error);
                  this.isLoading = false;
                  window.toastr['error'](response.data.error);
              }

        }
     }//
   
  }
  </script>
  