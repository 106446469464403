/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */
import router from './router.js'
import Layout from './helpers/layout'
import Plugin from './helpers/plugin'
import store from './store/index'
import utils from './helpers/utilities'
import { mapActions, mapGetters } from 'vuex'
import i18n from './plugins/i18n'
import swal from 'sweetalert'

require('./bootstrap')
Vue.prototype.$utils = utils
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
window.hub = new Vue()
window.i18n = i18n
window.Plugin = Plugin

/**
 * Introduced by Abhishek Sharma on 17th January 2019
 */
var myMixin = {
  created: function () {
    this.hello()
  },
  methods: {
    hello: function () {
      console.log('hello from mixin!')
    },
    // check_auth: function(){
    //   console.log(localStorage.getItem("auth.token"));
    //     var bodyFormData = new FormData();
    //     var authtoken=localStorage.getItem("auth.token");
    //     bodyFormData.append('token',authtoken);
    //     return axios({
    //       method: "post",
    //       url: `/api/auth/checktoken`,
    //       data: bodyFormData,
    //       headers: { "Content-Type": "multipart/form-data",Authorization: 'Bearer '+authtoken },
    //      })
    //       .then(function (response) {
    //          return response;
    //       }, error => {
    //         if (error.response.status === 401) {
    //             localStorage.clear();
    //             // window.location.href = '/auth';
    //         }
    //         // window.location.href = '/auth';
    //         return error.response.status;
    //       })
    //       .catch(function (error) {
    //         // console.log(error);
    //         localStorage.clear();
    //         window.location.href = '/api/auth/login';
    //       });
    //   }
      

    // }
      testing : function (){
      if(this.$route.query.id){
        localStorage.clear();
        window.setTimeout(function(){
          // Move to a new location or you can do something else
          window.location.href = "http://127.0.0.1:8000/admin/invoices";
        }, 5000);
      }
    }
  },
  beforeMount(){
    // this.check_auth()
     this.testing()
  },
  computed:{
    isInIframe(){
      try {
              if( window.self !== window.top ){
               
                //this.$router.push('/admin/invoices')
                return false
              }else{
                
                  //this.$router.push('/admin/dashboard')
                return true
              } 
          } catch (e) {
             
              //this.$router.push('/admin/invoices')
              return false
          }
    },



  }
}
/**
 * End of introduction by Abhishek Sharma
 */
const app = new Vue({
  router,
  store,
  i18n,
  swal,
  computed: {
    ...mapGetters([
      'isAdmin'
    ])
  },
  created: function() {
    // add event listener to handle tab/browser closing
    // and do logout
    window.addEventListener(
      "beforeunload",
      function(e) {
        this.alert("hiii");
      },
      false
    );
  },
  methods: {
    onOverlayClick () {
      this.$utils.toggleSidebar()
    }
  },
  
  mixins:[myMixin]
}).$mount('#app')
