var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c("div", [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.expense_category.title"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.expense_category.title")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "base-button",
            {
              staticClass: "add-new-tax",
              attrs: { outline: "", color: "theme" },
              on: { click: _vm.openCategoryModal },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.expense_category.add_new_category")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.categories,
            "table-class": "table expense-category",
          },
        },
        [
          _c("table-column", {
            attrs: {
              label: _vm.$t("settings.expense_category.category_name"),
              show: "name",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.expense_category.category_description"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.expense_category.category_description"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notes" }, [
                      _c("div", { staticClass: "note" }, [
                        _vm._v(_vm._s(row.description)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.expense_category.action"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      [
                        _c(
                          "a",
                          {
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [_c("dot-icon")],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.EditCategory(row.id)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "pencil-alt"] },
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.edit")) +
                                  "\n              "
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.removeExpenseCategory(row.id)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "trash"] },
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n              "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }