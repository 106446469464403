var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content dashboard", attrs: { id: "app" } },
    [
      _c("div", { staticClass: "row" }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 mt-2" }, [
          _c("div", { staticClass: "card dashboard-card" }, [
            _c("div", { staticClass: "graph-body" }, [
              _c(
                "div",
                { staticClass: "card-body col-md-12 col-lg-12 col-xl-10" },
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c("h6", [
                      _c("i", { staticClass: "fa fa-line-chart text-primary" }),
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.monthly_chart.title")) + " "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "year-selector" },
                      [
                        _c("base-select", {
                          attrs: {
                            options: _vm.years,
                            "allow-empty": false,
                            "show-labels": false,
                            placeholder: _vm.$t("dashboard.select_year"),
                          },
                          model: {
                            value: _vm.selectedYear,
                            callback: function ($$v) {
                              _vm.selectedYear = $$v
                            },
                            expression: "selectedYear",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isLoaded
                    ? _c("line-chart", {
                        attrs: {
                          "format-money": _vm.$utils.formatMoney,
                          "format-graph-money": _vm.$utils.formatGraphMoney,
                          expenses: _vm.getChartExpenses,
                          labels: _vm.getChartMonths,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-desc col-md-12 col-lg-12 col-xl-2" },
                [
                  _c("div", { staticClass: "stats" }, [
                    _c("div", { staticClass: "description" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("dashboard.chart_info.total_expense")
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.isLoaded
                        ? _c(
                            "span",
                            {
                              staticClass: "total",
                              staticStyle: { color: "#FB7178" },
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.getTotalExpenses,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.getLoadedData ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row table-row" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }