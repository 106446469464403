<template> 
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div
          v-for="(menuItems, index) in menu"
          :key="index"
          class="menu-group"
        >
          <router-link
            v-for="(item, index1) in menuItems"
            :key="index1"
            :to="item.route"
            class="menu-item"
            @click.native="Toggle"
          >
            <font-awesome-icon :icon="item.icon" class="icon menu-icon" /> <span class="ml-3 menu-text">{{ $t(item.title) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  data () {
    return {
      sidebar: 'sidebar',
      menu: [

        [
          {
            title: 'navigation.dashboard',
            icon: 'tachometer-alt',
            route: '/admin/dashboard'
          },
          // {
          //   title: 'navigation.customers',
          //   icon: 'user',
          //   route: '/admin/customers'
          // },

          
          // {
          //   title: 'navigation.items',
          //   icon: 'star',
          //   route: '/admin/items'
          // },

             {
            title: 'settings.menu_title.expense_category',
            icon: 'star',
            route: '/admin/expense_cat',
            },
            {
            title: 'navigation.expenses',
            icon: 'space-shuttle',
            route: '/admin/expenses'
          },
        ],

        // [
        
        //   //  {
        //   //   title: 'navigation.expenses',
        //   //   icon: 'space-shuttle',
        //   //   route: '/admin/expenses'
        //   // },
        //   // {
        //   //   title: 'navigation.payments',
        //   //   icon: 'credit-card',
        //   //   route: '/admin/payments'
        //   // }
        // ],

        [
          {
            title: 'navigation.reports',
            icon: 'signal',
            route: '/admin/reports'
          },
          {
            title: 'navigation.settings',
            icon: 'cog',
            route: '/admin/settings'
          }
        ]

      ]
    }
  },

  methods: {
    Toggle () {
      this.$utils.toggleSidebar()
    }
  },
  mounted: function () {
  this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered
       
       try {
              if( window.self !== window.top ){
                
                //this.$router.push('/admin/invoices')
                document.getElementsByClassName('sidebar-left')[0].remove();
                document.getElementsByClassName('site-header')[0].remove();
                document.getElementsByClassName('main-content')[0].style.padding = "30px 30px 10px 43px";
                return false
              }else{
               
                  //this.$router.push('/admin/dashboard')
                return true
              } 
          } catch (e) {
              
              //this.$router.push('/admin/invoices')
              document.getElementsByClassName('sidebar-left')[0].remove();
              document.getElementsByClassName('site-header')[0].remove();
              document.getElementsByClassName('main-content')[0].style.padding = "30px 30px 10px 43px";
              return false
          }
    
 
  })
},

  
}
</script>
